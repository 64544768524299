import React from 'react'

const Technology = ({ name, image, url }) => (
    <li>
        <a target="_blank" href={url} rel="noopener noreferrer">
            <span className="image"><img src={image} alt={`${name} Logo`} /></span>
            <h3>{name}</h3>
        </a>
    </li>
)

export default Technology
