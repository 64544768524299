import picJavaScript from './src/components/images/technologies/javascript.png'
import picReact from './src/components/images/technologies/react.png'
import picRedux from './src/components/images/technologies/redux.png'
import picReduxSaga from './src/components/images/technologies/reduxsaga.png'
import picNodeJs from './src/components/images/technologies/nodejs.png'
import picGraphQl from './src/components/images/technologies/graphql.png'
import picApollo from './src/components/images/technologies/apollo.png'
import picNextJs from './src/components/images/technologies/nextjs.png'
import picGatsby from './src/components/images/technologies/gatsby.png'
import picJava from './src/components/images/technologies/java.png'
import picKotlin from './src/components/images/technologies/kotlin.png'
import picPlayFramework from './src/components/images/technologies/playframework.png'
import picSpringFramework from './src/components/images/technologies/springframework.png'
import picVertX from './src/components/images/technologies/vertx.png'
import picPostgresql from './src/components/images/technologies/postgresql.png'
import picMysql from './src/components/images/technologies/mysql.png'
import picPhp from './src/components/images/technologies/php.png'
import picDocker from './src/components/images/technologies/docker.png'
import picGoogleAnalytics from './src/components/images/technologies/googleanalytics.png'
import picAws from './src/components/images/technologies/aws.png'

const technologies = [
    {
        name: "JavaScript",
        image: picJavaScript,
        url: "https://developer.mozilla.org/bm/docs/Web/JavaScript"
    },
    {
        name: "Node.js",
        image: picNodeJs,
        url: "https://nodejs.org/en/"
    },
    {
        name: "React",
        image: picReact,
        url: "https://reactjs.org"
    },
    {
        name: "Redux",
        image: picRedux,
        url: "https://redux.js.org"
    },
    {
        name: "Redux Saga",
        image: picReduxSaga,
        url: "https://redux-saga.js.org"
    },
    // {
    //     name: "GraphQL",
    //     image: picGraphQl,
    //     url: "https://graphql.org"
    // },
    // {
    //     name: "Apollo",
    //     image: picApollo,
    //     url: "https://www.apollographql.com"
    // },
    {
        name: "Next.js",
        image: picNextJs,
        url: "https://nextjs.org"
    },
    {
        name: "Gatsby",
        image: picGatsby,
        url: "https://gatsbyjs.org"
    },
    {
        name: "Java",
        image: picJava,
        url: "https://java.com"
    },
    {
        name: "Kotlin",
        image: picKotlin,
        url: "https://kotlinlang.org"
    },
    {
        name: "Play",
        image: picPlayFramework,
        url: "https://www.playframework.com"
    },
    {
        name: "Spring",
        image: picSpringFramework,
        url: "https://spring.io"
    },
    {
        name: "Vert.x",
        image: picVertX,
        url: "https://vertx.io"
    },
    {
        name: "PostgreSQL",
        image: picPostgresql,
        url: "https://www.postgresql.org"
    },
    {
        name: "MySQL",
        image: picMysql,
        url: "https://www.mysql.com"
    },
    {
        name: "PHP",
        image: picPhp,
        url: "http://php.net"
    },
    {
        name: "Amazon Web Services",
        image: picAws,
        url: "https://aws.amazon.com"
    },
    {
        name: "Docker",
        image: picDocker,
        url: "https://www.docker.com"
    },
    {
        name: "Google Analytics",
        image: picGoogleAnalytics,
        url: "https://www.google.com/analytics"
    }
]

export default technologies;