import React from 'react'
import Slider from 'react-slick'
import Lightbox from 'react-image-lightbox'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import picWilmaaWebTvThumb from './images/wilmaa_web_tv_thumb.jpg'
import picWilmaaWebTv1 from './images/wilmaa_web_tv_1.jpg'
import picWilmaaWatchCareThumb from './images/wilmaa_watch_care_thumb.jpg'
import picWilmaaWatchCare1 from './images/wilmaa_watch_care_1.jpg'

const carouselSettings = {
    touchThreshold: 5,
    dots: true,
    arrows: false
}

const showcases = [
    {
        title: "Wilmaa Web TV",
        thumb: picWilmaaWebTvThumb,
        images: [
            picWilmaaWebTv1,
        ],
        descriptionParagraphs: [
            "We consulted with one of the largest online TV providers in Switzerland on the transition from a dated " +
            "Flash-based web player to a modern HTML5 solution.",

            "Perspectual devised the front-end architecture using state-of-the-art technologies and collaborated " +
            "with the in-house team on the implementation of the project.",
        ],
        stack: "React, Redux, Redux Saga, Next.js, Node.js, Clappr Player"
    },
    {
        title: "Wilmaa Watch & Care",
        thumb: picWilmaaWatchCareThumb,
        images: [
            picWilmaaWatchCare1,
        ],
        descriptionParagraphs: [
            "Perspectual developed a solution that enables Wilmaa viewers to collect points for a charity by " +
            "watching TV and zapping through channels.",

            "We leveraged Node.js to implement a real-time usage tracking system that updates connected clients " +
            "through a websocket. On the front-end we added an interactive dashboard that allows the user to keep " +
            "track of the collected points and overall campaign progress.",
        ],
        stack: "Node.js, Socket.IO, MySQL, React, Victory Charts, Redux, Redux Saga"
    },
]

class Showcases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: null,
            imageIndex: 0,
            isOpen: false,
        };
    }

    componentDidMount() {
        // Disable touchmove to prevent scrolling entire page
        const carousel = document.getElementsByClassName('showcases-slider')[0];
        carousel.addEventListener('touchstart', this.touchStart);
        carousel.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    handlePrevClick = () => {
        this.slider.slickPrev();
    }

    handleNextClick = () => {
        this.slider.slickNext();
    }

    handleCloseRequest = () => {
        this.setState({ isOpen: false })
        enableBodyScroll(this.targetElement);
    }

    handleAfterOpen = () => {
        this.targetElement = document.querySelector('.ReactModalPortal');
        disableBodyScroll(this.targetElement);
    }

    handleImageMouseDown = (e) => {
        if (e.screenX) {
            this.imageInteractionStartX = e.screenX;
        }
    }

    handleImageTouchStart = (e) => {
        if (e.screenX) {
            this.imageInteractionStartX = e.screenX;
        }
    }

    handleImageClick = (e, images) => {
        if (this.checkIfImageWasDragged(e)) {
            return;
        }

        this.showLightbox(images)
    }

    checkIfImageWasDragged = (e) => {
        if (!this.imageInteractionStartX) {
            return false
        }

        if (!e.screenX) {
            return false;
        }

        const deltaX = Math.abs(e.screenX - this.imageInteractionStartX);
        return deltaX > carouselSettings.touchThreshold;
    }

    showLightbox = (images) => {
        this.setState({
            isOpen: true,
            images,
            imageIndex: 0,
        })
    }

    touchStart = (e) => {
        this.firstClientX = e.touches[0].clientX;
    }

    preventTouch = (e) => {
        const clientX = e.touches[0].clientX - this.firstClientX;
        const horizontalScroll = Math.abs(clientX) > carouselSettings.touchThreshold;
        if (horizontalScroll) {
            e.preventDefault();
        }
    }

    render() {
        const {
            images,
            imageIndex,
            isOpen,
        } = this.state;

        return (
            <div className="showcases-slider">
                <Slider
                    {...carouselSettings}
                    ref={(c) => (this.slider = c)}
                >
                    {
                        showcases.map((showcase, index) => (
                            <div key={index}>
                                <div className="showcase-content2">
                            <span
                                className="image"
                                onMouseDown={this.handleImageMouseDown}
                                onTouchStart={this.handleImageTouchStart}
                                onClick={(e) => this.handleImageClick(e, showcase.images)}
                            >
                                <img src={showcase.thumb} alt={`${showcase.title} Sample Screen`} />
                            </span>
                                    <div className="brief">
                                        <h3>{showcase.title}</h3>
                                        {
                                            showcase.descriptionParagraphs.map((paragraph, index) => (
                                                <p key={index}>{paragraph}</p>
                                            ))
                                        }
                                        <p>Stack: { showcase.stack }</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
                <span
                    className="slider-arrow slider-arrow-prev"
                    onClick={this.handlePrevClick}
                ></span>
                <span
                    className="slider-arrow slider-arrow-next"
                    onClick={this.handleNextClick}
                ></span>
                {
                    (isOpen && imageIndex < (images.length - 1)) && (
                        <span>next</span>
                    )
                }
                {
                    isOpen && (
                        <Lightbox
                            mainSrc={images[imageIndex]}
                            nextSrc={imageIndex < (images.length - 1) ? images[(imageIndex + 1)] : null}
                            prevSrc={imageIndex > 0 ? images[(imageIndex - 1)] : null}
                            onCloseRequest={this.handleCloseRequest}
                            onMovePrevRequest={() =>
                                this.setState({
                                    imageIndex: (imageIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    imageIndex: (imageIndex + 1) % images.length,
                                })
                            }
                            onAfterOpen={this.handleAfterOpen}
                            enableZoom={false}
                            reactModalStyle={{
                                overlay: {
                                    zIndex: 100000,
                                },
                            }}
                        />
                    )
                }
            </div>
        )
    }
}

export default Showcases
