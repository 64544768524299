import React from 'react'
import Layout from '../components/layout'
import Technology from '../components/Technology'
import Showcases from '../components/Showcases'
import CopyrightNote from '../components/CopyrightNote'
import picWebDev from '../components/images/web_dev.jpg'
import technologies from '../../technologies'

const EMAIL_REGEX = /.+@.+/;

class IndexPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      mt: {
        href: null,
        link: null
      },
    }

    this.formErrors = {
      name: false,
      email: false,
      message: false
    }

    this.submitPressed = false
  }

  componentDidMount () {
    setTimeout(() => {
      var ml = ['%68%69', ['%63%6f%6d', '%70%65%72%73%70%65%63%74%75%61%6c'].reverse().join('.')].join('@');
      ml = unescape(ml)

      this.setState({
          mt: {
            href: 'ma' + 'ilto:' + ml, // eslint-disable-line
            link: ml
          }
        }
      )
    }, 2000)
  }

  validateField = (fieldName) => {
    var value = this.formNode[fieldName].value.trim()

    const hasError = !value
    if (hasError !== this.formErrors[fieldName]) {
      this.formErrors[fieldName] = hasError
      this.forceUpdate()
    }
  }

  validateName = () => {
    this.validateField('name')
  }

  validateEmail = () => {
    this.validateField('email')

    var hasError = this.formErrors.email

    if (!hasError) {
      var email = this.formNode.email.value.trim()
      hasError = !EMAIL_REGEX.test(email)
    }

    if (hasError !== this.formErrors.email) {
      this.formErrors.email = hasError
      this.forceUpdate()
    }
  }

  validateMessage = () => {
    this.validateField('message')
  }

  validateForm = () => {
    this.validateName()
    this.validateEmail()
    this.validateMessage()
  }

  hasAnyFormErrors = () =>
    Object
      .keys(this.formErrors)
      .map((key) => this.formErrors[key])
      .filter((value) => value).length > 0

  handleFormSubmit = (e) => {
    this.validateForm()

    if (this.hasAnyFormErrors()) {
      e.preventDefault()
    }

    if (!this.submitPressed) {
      this.submitPressed = true
      this.forceUpdate()
    }
  }

  render() {
    const { mt } = this.state
    const formErrors = this.formErrors
    const hasAnyFormErrors = this.hasAnyFormErrors()

    return (
      <Layout>
        <div>
          <div id="main">

            <section id="services" className="main">
              <div className="spotlight">
                <div className="content">
                  <header className="major">
                    <h2>What We Do</h2>
                  </header>
                  <p>Perspectual delivers tailor-made software solutions for the modern web. We enable businesses by
                    providing the right perspective on their technical challenges. Our goal is to design, code and
                    deploy rich online experiences that help our clients stay ahead of the competition.</p>
                </div>
                <span className="image"><img src={picWebDev} alt="Cross-Device Web Development" /></span>
              </div>
              <div className="features-wrapper">
                <header className="major">
                  <h3>Our Core Services</h3>
                </header>
                <ul className="features">
                  <li>
                    <span className="icon major style1 fa-code"></span>
                    <h3>Web Development</h3>
                    <p>Thanks to our extensive experience in building full-stack web solutions, we are your one-stop shop for custom applications. Our team uses agile development methodologies that produce frequent deliveries and help ensure that we properly understand your requirements and vision.</p>
                  </li>
                  <li>
                    <span className="icon major style3 fa-info"></span>
                    <h3>Consulting</h3>
                    <p>Over the years our team has successfully employed a plethora of technologies in a variety of contexts. We would love to share our knowledge with you, whether you are starting a new project from scratch or are looking to modernize your existing infrastructure.</p>
                  </li>
                  <li>
                    <span className="icon major style5 fa-cloud"></span>
                    <h3>Hosting &amp; Support</h3>
                    <p>Making sure that your application is running in a secure, scalable and reliable fashion can be a daunting task. Let us take care of all the heavy lifting behind the scenes. Our hosting & support offerings provide your business with the necessary assistance and peace of mind.</p>
                  </li>
                </ul>
              </div>
            </section>

            <section id="technologies" className="main special">
              <header className="major">
                <h2>What We Work With</h2>
              </header>
              <ul className="tech">
                {
                  technologies.map((technology, index) => (
                    <Technology
                      key={index}
                      name={technology.name}
                      image={technology.image}
                      url={technology.url}
                    />
                  ))
                }
              </ul>
            </section>

            <section id="showcases" className="main special">
              <header className="major">
                <h2>What We're Proud Of</h2>
              </header>
              <Showcases />
            </section>

            <section id="contact" className="main special">
              <header className="major">
                <h2>Did We Spark Your Interest?</h2>
              </header>
              <footer id="footer" className="major">
                <div className="inner">
                  <ul className="contact">
                    <li className="icon fa-envelope">
                      {
                        mt.href ? (<a href={mt.href}>{ mt.link }</a>) : 'Loading...'
                      }
                    </li>
                    <li className="icon fa-home">
                      Suite 2, Level 12A, Enterprise Building<br />
                      228-238 Queens Road Central<br />Hong Kong
                    </li>
                  </ul>
                  {/*<form
                    ref={(node) => this.formNode = node}
                    onSubmit={this.handleFormSubmit}
                    name="contact"
                    action="/contact-success/"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="hnp"
                    noValidate
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="fields">
                      <div className={`field ${formErrors.name ? 'is-error' : ''}`}>
                        <label htmlFor="name">Name</label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Name"
                          autoComplete="name"
                          tabIndex="1"
                          onBlur={this.validateName}
                        />
                        {
                          formErrors.name && <div className="error-text">Please specify a name.</div>
                        }
                      </div>
                      <div className={`field ${formErrors.email ? 'is-error' : ''}`}>
                        <label htmlFor="email">Email</label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          tabIndex="2"
                          onBlur={this.validateEmail}
                        />
                        {
                          formErrors.email && <div className="error-text">Please specify a valid e-mail address.</div>
                        }
                      </div>
                      <div className="field hnp">
                        <label htmlFor="hnp">Question</label>
                        <input
                          name="hnp"
                          id="hnp"
                          type="text"
                          placeholder="How did you find out about us?"
                          tabIndex="5"
                        />
                      </div>
                      <div className={`field ${formErrors.message ? 'is-error' : ''}`}>
                        <label htmlFor="message">Message</label>
                        <textarea
                          name="message"
                          id="message"
                          rows="6"
                          placeholder="Message"
                          tabIndex="3"
                          onBlur={this.validateMessage}
                        ></textarea>
                        {
                          formErrors.message && <div className="error-text">Please specify a message.</div>
                        }
                      </div>
                    </div>
                    <ul className="actions">
                      <li className={`${hasAnyFormErrors && this.submitPressed ? 'is-error' : ''}`}>
                        <input value="Send Message" className="button large" type="submit" tabIndex="4" />
                        {
                          hasAnyFormErrors && this.submitPressed &&
                          <div className="error-text error-text-submit">Your form contains errors, please check it.</div>
                        }
                      </li>
                    </ul>
                  </form>*/}
                </div>
                <CopyrightNote />
              </footer>
            </section>

          </div>

        </div>
      </Layout>
    )
  }
}

export default IndexPage
